export const InitHeader = () => {
  const header = document.getElementById('header');
  const nav = header.getElementsByClassName('nav-link');
  let isIE = false;
  if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
      navigator.userAgent.indexOf('Trident/') > -1 ) {

    isIE =true;
  }

  document.getElementById('logo').addEventListener('click', () => {
    if (!isIE) {
      const e = document.getElementById(document.getElementById('logo')
          .getAttribute('to'));
      e.scrollIntoView({behavior: 'smooth'});
    } else {
      window.location.href=`#item.getAttribute('to')`;
    }
  })


  for (const item of nav) {
    item.addEventListener('click', () => {
      if (!isIE) {
        const e = document.getElementById(item.getAttribute('to'));
        e.scrollIntoView({behavior: 'smooth'});
      } else {
        window.location.href=`#item.getAttribute('to')`;
      }
    });
  }

  if (header) {
    const disableScrolled = () => {
      if (header.classList.contains('header-scrolled') && scrolled) {
        header.classList.remove('header-scrolled');
        scrolled = false;
      }
    };

    const enableScrolled = () => {
      if (!header.classList.contains('header-scrolled') && !scrolled) {
        header.classList.add('header-scrolled');
        scrolled = true;
      }
    };

    let scrolled = false;

    const check = () => {
      if (document.body.scrollTop || document.documentElement.scrollTop > 10) {
        enableScrolled();
      } else {
        disableScrolled();
      }
    };

    check();
    window.addEventListener('scroll', () => {
      check();
    });
  }
};


