require('jquery');
require('popper.js');
require('bootstrap');

import {InitBackToTop} from './elements/back-to-top';
import {InitHeader} from './sections/header';
import {InitPortfolioSection} from './sections/portfolio';

import AOS from 'aos';


AOS.init({disable: 'mobile'});
InitBackToTop.call();
InitHeader.call();
InitPortfolioSection.call();
