
export const InitBackToTop = () => {
  const backToTop = document.getElementById('back-to-top');
  if (backToTop) {
    const showBackToTopDisplay = () => {
      if (backToTop.classList.contains('hide') && backToTopHidden) {
        backToTop.classList.remove('hide');
        backToTopHidden = false;
      }
    };

    const showBackToTopHide = () => {
      if (!backToTop.classList.contains('hide') && !backToTopHidden) {
        backToTop.classList.add('hide');
        backToTopHidden = true;
      }
    };

    let backToTopHidden = true;

    backToTop.onclick = function() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    };


    const check = () => {
      if (document.body.scrollTop || document.documentElement.scrollTop > 300) {
        showBackToTopDisplay();
      } else {
        showBackToTopHide();
      }
    };

    check();
    window.addEventListener('scroll', () => {
      check();
    });
  }
};


