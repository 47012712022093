export const InitPortfolioSection = () => {
  const projects = {
    'Debdu': {
      title: 'Debdu<br>Cleaning company',
      description: 'Debdu is platform that allows experienced<br>\n' +
          'cleaners to find new customers in their area.<br>\n' +
          ' Whether yo\'ve just started your cleaning business or\n' +
          ' have<br>\n' +
          'been your own boss for decades, Debdu can help<br>\n' +

          'you grow your customer base and increase your earnings.',
      skills: [{title: 'ASP.NET Core', part: 40, color: '"#9b59b6'},
        {title: 'Angular JS', part: 40, color: '#1636ff'},
        {title: 'JS', part: 10, color: '#27ae60'},
        {title: 'CSS3', part: 10, color: '#ffbb00'}],
    },
    'Landmark': {
      title: `Sotheby's International Realty®`,
      description: 'Sotheby\'s International Realty® is a registered<br>\n' +
          'trademark licensed to Sotheby\'s International\n' +
          'ealty<br>\n' +
          'Affiliates LLC. Each office is independently owned\n' +
          'and\n' +
          'operated.<br>\n' +
          'Licensed in the Commonwealth of Massachusetts.',
      skills: [{title: 'HTML5', part: 50, color: '#bbaaee'},
        {title: 'CSS3', part: 50, color: '#bbaaee'},
        {title: 'JS', part: 50, color: '#bbaaee'}]
    },
    'BigSky': {
      title: `Sotheby's International Realty®`,
      description: 'Sotheby\'s International Realty® is a registered<br>\n' +
          'trademark licensed to Sotheby\'s International\n' +
          'ealty<br>\n' +
          'Affiliates LLC. Each office is independently owned\n' +
          'and\n' +
          'operated.<br>\n' +
          'Licensed in the Commonwealth of Massachusetts.',
      skills: [{title: 'HTML5', part: 50, color: '#bbaaee'},
        {title: 'CSS3', part: 50, color: '#bbaaee'},
        {title: 'JS', part: 50, color: '#bbaaee'}]
    },
    'NewAge': {
      title: `Sotheby's International Realty®`,
      description: 'Sotheby\'s International Realty® is a registered<br>\n' +
          'trademark licensed to Sotheby\'s International\n' +
          'ealty<br>\n' +
          'Affiliates LLC. Each office is independently owned\n' +
          'and\n' +
          'operated.<br>\n' +
          'Licensed in the Commonwealth of Massachusetts.',
      skills: [{title: 'HTML5', part: 50, color: '#bbaaee'},
        {title: 'CSS3', part: 50, color: '#bbaaee'},
        {title: 'JS', part: 50, color: '#bbaaee'}]
      ,
    },
    'Wallace': {
      title: `Sotheby's International Realty®`,
      description: 'Sotheby\'s International Realty® is a registered<br>\n' +
          'trademark licensed to Sotheby\'s International\n' +
          'ealty<br>\n' +
          'Affiliates LLC. Each office is independently owned\n' +
          'and\n' +
          'operated.<br>\n' +
          'Licensed in the Commonwealth of Massachusetts.',
      skills: [{title: 'HTML5', part: 50, color: '#bbaaee'},
        {title: 'CSS3', part: 50, color: '#bbaaee'},
        {title: 'JS', part: 50, color: '#bbaaee'}],
    },

  };


  const portfolioItems = document.getElementById('portfolio-container')
      .getElementsByClassName('portfolio-item');
  for (const item of portfolioItems) {
    const temp = item.getElementsByClassName('link-preview');
    if (temp.length > 0) {
      temp[0].addEventListener('click', () => {
        openModel(temp[0].getAttribute('project'));
      });
    }
  }
  const btnCon = document.getElementById('portfolio-filters');
  if (btnCon) {
    btnCon.childNodes.forEach((element) => {
      element.onclick = () => {
        btnCon.childNodes.forEach((e) => {
          if (e === element) {
            if (!e.classList || !e.classList.contains('filter-active')) {
              e.classList.add('filter-active');
            }
          } else {
            if (e.classList && e.classList.contains('filter-active')) {
              e.classList.remove('filter-active');
            }
          }
        });
        toggle(element.getAttribute('data-show'));
      };
    });
  }


  const toggle = (type) => {
    if (type === '*') {
      for (const item of portfolioItems) {
        item.style.display = 'block';
      }
    } else {
      for (const item of portfolioItems) {
        if (item.classList.contains(type)) {
          item.style.display = 'block';
        } else {
          item.style.display = 'none';
        }
      }
    }
  };

  const openModel = (item) => {
    document.getElementById('portfolio-info-title')
        .innerHTML = projects[item].title;
    document.getElementById('portfolio-info-description')
        .innerHTML = projects[item].description;
    const skillsDiv = document.getElementById('skills');
    skillsDiv.innerText = '';
    let i = 1;
    projects[item].skills.forEach((e) => {
      const innerli = document.createElement('li');
      const title = document.createElement('p');
      title.innerText = e.title;
      innerli.appendChild(title)
      const innerDiv = document.createElement('div');
      innerDiv.className = 'meter w-100';
      const span = document.createElement('span');
      span.style.backgroundColor = `${e.color}`;
      setTimeout(() => {
        span.style.width = `${e.part}%`;
      }, 500 + (200*i));
      i=i+1;
      innerDiv.appendChild(span);
      innerli.appendChild(innerDiv);
      skillsDiv.appendChild(innerli);
    });
  };
}
